import { Injectable } from '@angular/core';

import { CookieService } from '../services/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly _tokenKeyStorage = 'accessToken';
  private readonly _refreshTokenKeyStorage = 'refreshToken';

  constructor(private storage: CookieService) {}

  saveAccessToken(token: string) {
    this.storage.setItem(this._tokenKeyStorage, token);
  }

  getAccessToken(): string {
    return this.storage.getItem(this._tokenKeyStorage);
  }

  removeAccessToken() {
    this.storage.removeItem(this._tokenKeyStorage);
  }

  saveRefreshToken(token: string) {
    this.storage.setItem(this._refreshTokenKeyStorage, token);
  }

  getRefreshToken(): string {
    return this.storage.getItem(this._refreshTokenKeyStorage);
  }

  removeRefreshToken() {
    this.storage.removeItem(this._refreshTokenKeyStorage);
  }

  removeToken() {
    this.removeAccessToken();
    this.removeRefreshToken();
  }
}
