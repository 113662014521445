import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

export interface ModalConfirmComponentData {
  title?: string;
  message: string;
  type?: 'primary' | 'danger';
}

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent {
  readonly #modal = inject(NzModalRef);
  readonly modalData: ModalConfirmComponentData = inject(NZ_MODAL_DATA);

  handleOk() {
    this.#modal.destroy(true);
  }

  handleCancel() {
    this.#modal.destroy(false);
  }
}
