import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@core/guards/auth.guard';
import { loginGuard } from '@core/guards/login.guard';
import { PageNotFoundComponent } from '@public/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [loginGuard],
    loadChildren: () => import('@public/components/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    canActivate: [loginGuard],
    loadChildren: () =>
      import('@public/components/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: '',
    canActivate: [authGuard],
    loadChildren: () => import('@private/private.module').then((m) => m.PrivateModule),
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
