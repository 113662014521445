export enum routes {
  LOGIN = '/login',
  REGISTER = '/register',
  DASHBOARD = '/dashboard',
  BOOK = '/book',
  USER = '/user',
  CATEGORY = '/category',
  TAG = '/tag',
  AUTHOR = '/author',
  SETTING = '/setting',
  CONFIGURATION = '/configuration',
  FILE = '/file',
  ROLE = '/role',
}
