<section class="section is-medium">
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column has-text-centered">
        <h1 class="title">404 Page Not Found</h1>
        <p class="subtitle">An unexpected error has occurred. Please contact the site owner.</p>
        <div class="buttons is-centered">
          <a class="button" href="/dashboard">Home</a>
        </div>
      </div>
      <div class="column has-text-centered">
        <img src="./assets/img/404.gif" alt="" />
      </div>
    </div>
  </div>
</section>
