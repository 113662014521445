<!-- Modal header -->
<div
  class="pb-4 md:pb-5 flex items-center justify-between border-b rounded-t dark:border-gray-600"
>
  <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
    {{ modalData.title || "Confirm" }}
  </h3>
</div>
<!-- Modal body -->
<div class="p-4 md:p-5 space-y-4">
  <div class="p-4 md:p-5 text-center">
    <svg
      class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
      {{ modalData.message }}
    </h3>
  </div>
</div>
<!-- Modal footer -->
<div
  class="flex justify-end items-center pt-4 md:pt-5 border-t border-gray-200 rounded-b dark:border-gray-600"
>
  <button
    type="button"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    *ngIf="!modalData.type || modalData.type === 'primary'"
    (click)="handleOk()"
  >
    OK
  </button>
  <button
    data-modal-hide="popup-modal"
    type="button"
    class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
    *ngIf="modalData.type === 'danger'"
    (click)="handleOk()"
    >
    OK
  </button>
  <button
    type="button"
    class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    (click)="handleCancel()"
  >
    Cancel
  </button>
</div>
