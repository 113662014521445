import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ApiMethod } from './const';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  readonly API_URL = environment.apiUrl;
  _http: HttpClient;

  constructor(injector: Injector) {
    this._http = injector.get(HttpClient);
  }

  requestCall(
    api: string,
    method: ApiMethod,
    queryParameter?: any,
    bodyParameter?: any,
  ): Promise<unknown> {
    switch (method) {
      case ApiMethod.GET:
        return firstValueFrom(this._http.get(`${this.API_URL}${api}`, { params: queryParameter }));
      case ApiMethod.POST:
        return firstValueFrom(
          this._http.post(`${this.API_URL}${api}`, bodyParameter, {
            params: queryParameter,
          }),
        );
      case ApiMethod.DELETE:
        return firstValueFrom(
          this._http.delete(`${this.API_URL}${api}`, {
            params: queryParameter,
            body: bodyParameter,
          }),
        );
      case ApiMethod.PUT:
        return firstValueFrom(
          this._http.put(`${this.API_URL}${api}`, bodyParameter, {
            params: queryParameter,
          }),
        );
      case ApiMethod.PATCH:
        return firstValueFrom(this._http.patch(`${this.API_URL}${api}`, bodyParameter));
      default:
        throw new Error('Unsupported method');
    }
  }
}
