import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routes } from '@consts/routes';
import { AuthRefreshModel } from '@core/models';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private abortController = new AbortController();

  constructor(
    private _authService: AuthService,
    private _tokenService: TokenService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this._tokenService.getAccessToken();
    if (token) {
      request = this.addAccessTokenToRequest(request, token);
    }
    return next.handle(request).pipe(catchError((error) => this.handleError(error, request, next)));
  }

  private addAccessTokenToRequest(request: HttpRequest<unknown>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<unknown>, next: HttpHandler) {
    if (
      error instanceof HttpErrorResponse &&
      error.status === 401 &&
      !request.url.includes(routes.LOGIN)
    ) {
      return this.handleUnauthorizedError(request, next);
    }
    return throwError(() => error);
  }

  private handleUnauthorizedError(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next('');
      this.abortController.abort();
      this.abortController = new AbortController();
      return this._authService.refreshToken<AuthRefreshModel>().pipe(
        switchMap(({ accessToken }) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(accessToken);
          this._tokenService.saveAccessToken(accessToken);
          request = this.addAccessTokenToRequest(request, accessToken);
          return next.handle(request);
        }),
        catchError((error) => {
          this.refreshTokenInProgress = false;
          this._authService.logout();
          return throwError(() => error);
        }),
      );
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => !token),
      take(1),
      switchMap((accessToken) => {
        return next.handle(this.addAccessTokenToRequest(request, accessToken));
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next('');
      return this._authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.refreshTokenInProgress = false;

          this._tokenService.saveAccessToken(token.accessToken);
          this._tokenService.saveRefreshToken(token.accessToken);
          this.refreshTokenSubject.next(token.accessToken);

          return next.handle(this.addAccessTokenToRequest(request, token.accessToken));
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;

          this._tokenService.removeToken();
          return throwError(() => err);
        }),
      );
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      switchMap((jwt: unknown) => {
        return next.handle(this.addAccessTokenToRequest(request, jwt as string));
      }),
    );
  }
}

/**
 * Export provider
 */
export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
};
