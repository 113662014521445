import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '@consts/routes';
import { ApiMethod } from '@shared/services/const';
import { HttpService } from '@shared/services/http.service';

import { AuthEndPoint } from './const';
import { CookieService } from './cookie.service';
import { TokenService } from './token.service';
import { LoginPayload, RegisterPayload } from '../interfaces/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends HttpService {
  user: unknown = {};

  constructor(
    injector: Injector,
    private _token: TokenService,
    private _storage: CookieService,
    private _router: Router,
  ) {
    super(injector);
    this.init();
  }

  login(email: string, password: string) {
    const params: LoginPayload = { email, password };
    return this.requestCall(AuthEndPoint.LOGIN, ApiMethod.POST, undefined, params);
  }

  isLoggedIn() {
    return !!this._token.getAccessToken();
  }

  getUserLogin() {
    try {
      const userData = this._storage.getItem('user');
      return JSON.parse(userData);
    } catch (error) {
      return {};
    }
  }

  logout() {
    this._token.removeToken();
    this.removeUserLogin();
    this._router.navigateByUrl(routes.LOGIN);
  }

  refreshToken<T>() {
    const params = { refreshToken: this._token.getRefreshToken() };
    return this._http.post<T>(`${this.API_URL}${AuthEndPoint.REFRESH_TOKEN}`, params);
  }

  register(params: RegisterPayload) {
    return this.requestCall(AuthEndPoint.REGISTER, ApiMethod.POST, undefined, params);
  }

  private init() {
    if (this.isLoggedIn()) {
      this.user = this.getUserLogin();
    }
  }

  async doLogin(data: { accessToken?: string; refreshToken?: string }) {
    if (data?.accessToken) {
      this._token.saveAccessToken(data?.accessToken);
      this._token.saveRefreshToken(data?.refreshToken ?? '');
      await this.fetchUserLogin();
      this._router.navigateByUrl('/');
    }
  }

  private async fetchUserLogin() {
    const res: any = await this.requestCall(AuthEndPoint.CURRENT_USER, ApiMethod.GET);
    if (res) {
      this._storage.setItem('user', JSON.stringify(res));
    }
  }

  private removeUserLogin() {
    this._storage.removeItem('user');
  }
}
