import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ModalConfirmModule } from '@shared/components/modal-confirm/modal-confirm.module';

import { SlugifyPipe } from '../shared/pipes/slugify.pipe';

@NgModule({
  declarations: [SlugifyPipe],
  imports: [CommonModule, HttpClientModule, ModalConfirmModule],
})
export class CoreModule {}
