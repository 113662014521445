import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { routes } from '@consts/routes';

import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _state: RouterStateSnapshot,
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return !authService.isLoggedIn() ? router.navigateByUrl(routes.LOGIN) : true;
};
